@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
// @import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply p-3;
  }

  .link {
    @apply text-primary-P400 cursor-pointer hover:opacity-70 hover:text-primary-P400;
  }

  .delete {
    color: rgb(226, 15, 18) !important;
  }

  .green {
    color: rgb(15, 226, 29) !important;
  }

  .sampleDropdown {
    position: fixed;
    top: 13%;
    z-index: 100;
  }
}

.w-40 {
  width: 30%;
}

.ant-upload-list-item-name {
  color: white;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  background: #08090a;
  caret-color: #92928f;
}

/* Custom bg and colors  */
.bg-custom-secondary {
  background-color: #1e1e2d;
}

.bg-custom-main {
  background: #151521;
}

.custom-text-light {
  color: #474761;
}

.custom-blue-bg {
  background-color: #3699ff;
}

.border-dashed-bottom {
  border-bottom: 1px dashed #323248;
}

.password-stength span {
  height: 4px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.407);
}

::-webkit-scrollbar-thumb {
  background-color: #4e89c9;
  // background-image: linear-gradient(#d1dbe4, #4e89c9, #8299b2);
  border-radius: 10px;
  // background-image: linear-gradient(180deg, #333334 0%, #1e1e2d 99%);
  border-radius: 100px;
}

// SWITCH
.ant-switch {
  background-color: #323248;
  border-color: #323248;
  color: #92928f;
}

.ant-switch-checked {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff;

  &:focus {
    border-color: #3699ff;
    box-shadow: unset;
  }
}

.ant-switch-loading,
.ant-switch-disabled {
  opacity: 0.8;
}

// CHECKBOX
.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background-color: #3699ff;
    border-color: #3699ff;

    &:after {
      background-color: #fff;
    }
  }
}

.ant-checkbox {
  &:hover {
    .ant-checkbox-checked {
      &::after {
        border-radius: 4px;
      }
    }
  }
}

.ant-checkbox-checked {
  &:hover {
    border-radius: 4px;
  }

  .ant-checkbox-inner {
    background: #3699ff;
    border-radius: 4px;

    &:hover {
      border-radius: 4px;
    }
  }
}

.ant-checkbox-inner {
  background: #2b2b40;
  outline: none;
  border: none;
  border-radius: 4px;

  &:hover {
    border-radius: 4px;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background: #5a5a6cbd;
}

// SELECT
// .custom-select-dropdown {
//   background-color: #171723 !important;

//   .ant-select-item-option-content {
//     color: #6d6d80 !important;
//   }

//   .ant-select-item-option:not(.ant-select-item-option-disabled):hover {
//     &:hover {
//       background-color: #28283a !important;
//     }

//     .ant-select-item-option-content {
//       color: #fff !important;
//     }
//   }

//   .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//     background-color: #28283a !important;

//     .ant-select-item-option-content {
//       color: #fff !important;
//     }
//   }

//   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//     background-color: #28283a !important;

//     .ant-select-item-option-content {
//       color: #fff !important;
//     }
//   }
// }

// .custom-select-component {
//   .ant-select {
//     border-radius: 8px;

//     .ant-select-selector {
//       background-color: #171723;
//       border-color: #171723;
//       min-height: 52px;
//       display: flex;
//       align-items: center;
//       padding: 0px;
//       padding-left: 12px;
//       border-radius: 8px;
//     }

//     .ant-select-selection-item {
//       color: #92928f;
//     }
//   }
// }

.custom-radio {
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-inner {
    background-color: transparent;
    border-color: #323248;
  }
}

.custom-btn-primary {
  background-color: #3699ff;
}

.custom-btn-danger {
  background-color: #ff4d4f;
}

.error {
  color: #ff0000;
}
.title {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}
.subtitle {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c757d;
}
.input {
  background: #0c0e0f;
  border: 1px solid #2e3136;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6c757d;
}
.form-label {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.text-red-600 {
  font-family: "Poppins", sans-serif !important;
  color: #f2555a !important;
  text-align: end;
}
.submitButton {
  background: #023e8a;
  border-radius: 8px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.cancelButton {
  background: #08090a;
  border: 1px solid #2e3136;
  border-radius: 8px;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.forgotpwd {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #6c757d;
}
.logoname {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.logoname-menu {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}
.bg-color {
  background: linear-gradient(180deg, rgba(0, 150, 199, 0) 50%, #0096c7 500%);
  min-height: 100vh;
  height: 100vh;
  // padding: 10px;
  overflow-y: auto;
}
.when-active {
  background: linear-gradient(
    90deg,
    rgba(2, 62, 138, 0.24) 50%,
    rgba(2, 62, 138, 0) 120%
  ) !important;
  border-left: 3px solid #0096c7 !important;
}
a:active {
  color: #0096c7 !important;
}
.footer-menu {
  width: 199.06px;
  height: 243.06px;
}
.content-header {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
}
.side-menu {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.side-menu:hover :active {
  color: #0096c7;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

a:hover {
  text-decoration: none;
  color: #fff;
}

// .logo-position {
//   display: flex;
//   position: absolute;
//   // left: 30.91%;
//   right: 31.26%;
//   top: 38%;
//   bottom: 60.73%;
//   z-index: -1;
// }

.border {
  border: 1px solid #2e3136 !important;
}
//Remove Number input up donw btn

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Antd select design
// .ant-select,
// .ant-select-selector,
// .ant-select-selection-search,
// .ant-select-selection-item,
// .ant-select-show-arrow-open {
//   background-color: #242429;
// }

// .ant-select-selection {
//   background-color: #242429 !important;
// }

pre {
  overflow: auto !important;
}

.space {
  background: linear-gradient(180deg, rgba(0, 150, 199, 0) 50%, #0096c7 500%)
    center / 200px 200px round;
  // background: linear-gradient(180deg, rgba(0, 150, 199, 0) 50%, #0096c7 500%);

  // border: 1px dashed purple;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes space {
  40% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.25;
  }
  60% {
    opacity: 0.75;
  }
  100% {
    transform: rotate(360deg);
  }
}

.stars1 {
  animation: space 180s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 25px 5px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 50px 25px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 125px 20px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 50px 75px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 15px 125px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 110px 80px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    );
}

.stars2 {
  animation: space 240s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 75px 125px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 100px 75px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 199px 100px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 20px 50px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 100px 5px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 5px 5px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    );
}

.stars3 {
  animation: space 300s ease-in-out infinite;
  background-image: radial-gradient(
      1px 1px at 10px 10px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1px 1px at 150px 150px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 60px 170px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      1.5px 1.5px at 175px 180px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2px 2px at 195px 95px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      2.5px 2.5px at 95px 145px,
      rgba(255, 253, 253, 0.73),
      rgba(255, 255, 255, 0)
    );
}

.robotimg {
  width: 67%;
  padding-top: 15%;
  z-index: 2 !important;
  position: relative;
}
.t-buttonx {
  max-width: 0px;
  max-height: 0px;
  background-color: transparent;
  border: none;
}

.ant-tour-next-btn {
  background-color: #1677ff !important;
}

.ant-tooltip-inner {
  background-color: #0c0d0f !important;
}

.ant-tooltip-inner {
  border: 1px solid #2e3136 !important;
  padding: 5px 10px;
  border-radius: 10px;
  background: #242429 !important;
  // color: #0c0d0f !important;
}

.ant-tour-inner {
  // background-color: #0c0d0f !important;
  border: 1px solid #2e3136 !important;
  padding: 5px 10px;
  border-radius: 10px;
  background: #242429 !important;
  margin: 10px;
  margin-top: 5px;
  color: white;
  span {
    color: rgba(255, 255, 255, 0.715) !important;
  }
}

.ant-tour-indicator {
  background: rgb(255 255 255 / 15%) !important;
}
.ant-tour-indicator.ant-tour-indicator-active {
  background: #1677ff !important;
}

.ant-tour-arrow:before {
  background-color: #0c0d0f !important;
}

:where(.css-dev-only-do-not-override-k0e3jg).ant-spin-nested-loading
  > div
  > .ant-spin
  .ant-spin-text {
  font-weight: 400;
  text-shadow: 0 1px 2px #000;
  color: #0096c7;
  font-size: 18px;
}

.icx {
  position: absolute;
  right: 0;
  bottom: 0;
}

.my-chat {
  max-height: 60vh;
  min-height: 50vh;
}

@keyframes blink {
  50% {
    opacity: 0.3;
  }
}
.pansophyonlycss-loader__dot {
  animation: 0.4s blink infinite;
}
.pansophyonlycss-loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.pansophyonlycss-loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:where(.css-dev-only-do-not-override-1re80p9).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 0px;
}
